import React from 'react';
import PageSection from "../PageSection/PageSection";

import myPhoto from '../../assets/img/my_photo.jpg';

const AboutPage: React.FC = () => {

    return (
        <PageSection id="about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="mt-3">
                            <img src={myPhoto} alt="" className="img-fluid mx-auto d-block img-thumbnail" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mt-3">
                            <h2><span className="font-weight-bold">About</span> Me</h2>
                            <h4 className="mt-4">Hello! <span className="text-light-blue font-weight-bold">I am Toskas Dimitrios.</span>
                            </h4>
                            <p className="text-muted mt-4 text-justify">Senior software engineer with experience in
                                object-oriented software engineering specialized for web development. Extensive background in
                                software development life cycle of scalable systems according to software system models,
                                including gathering, design, implementation testing and maintenance.</p>
                            <p className="text-muted mt-2 text-justify">I love taking challenges and solve difficult problems.
                                Overcoming my limits offers me a feeling of satisfaction. I always look for opportunities to
                                learn more and meet new technologies.</p>
                            <p className="text-muted mt-2 text-justify">I have programming experience in different technologies
                                including embedded systems, web development and mobile computing.</p>
                        </div>
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

export default AboutPage;