import React, {useEffect} from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";

import ScrollSpy from 'bootstrap/js/dist/scrollspy';

import './MainMenu.css';


const MainMenu: React.FC = () => {
    useEffect(() => {
        new ScrollSpy(document.body, {
            target: '#mainMenu',
            offset: 100
        });
    },[]);
    return (
        <Navbar id="mainMenu" className="fixed-top py-3 main-nav" expand="lg">
            <Container>
                <Navbar.Brand className="pt-0 font-oswald logo" href="#home">Toskas Dimitrios</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#resume">Resume</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default MainMenu;
