import React from 'react';

import './ButtonLink.css';
export interface ButtonLinkProps {
    href: string,
    target?:string,
    className?:string
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ href, target, className, children }) => {
    return (
        <a href={href} target={target} className={`btn ${className}`}>{children}</a>
    );
};

export default ButtonLink;
