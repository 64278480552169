import React from 'react';

interface PageSectionProps {
    id: string,
    className?: string,
}

const PageSection: React.FC<PageSectionProps> = ({ id, className = '', children }) => {

    return (
        <section id={id} className={`page-section ${ className }`}>
            {children}
        </section>
    );
};

export default PageSection;
