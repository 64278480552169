import React, {Fragment} from 'react';
import PageSection from "../PageSection/PageSection";
import ResumePageItem from "./ResumePageItem";
import cvPdf from "../../assets/files/Dimitrios-Toskas-CV.pdf";
import WhiteLink from "../../components/UI/Links/ButtonLink/WhiteLink";

const ResumePage: React.FC = () => {
    const jobs = [
        {
            "url":"https://www.easymarkets.com/",
            "title":"Senior .Net Software Developer - EasyMarkets, Limassol, Cyprus",
            "period":"2021 - Present",
            "description":"Responsible for the design and implementation of the back end for a trading platform, integrating with Payment Providers, maintain and develop the internal Back Office, analyzing thousands of data, building APIs",
            "technologies":"VC#, ASP.Net, .Net Core, .Net Standard, WCF, Windows Services, RabbitMQ, ZeroMQ, MS SQL, jQuery, Bootstrap CSS, SCSS, Webpack, Redis, Memcache, AWS, xUnit Tests, GIT, Redmine."
        },
        {
            "url":"https://www.easymarkets.com/",
            "title":"Senior PHP Software Developer - EasyMarkets, Limassol, Cyprus",
            "period":"2018 - 2021",
            "description":"Responsible for the design and implementation of high available and scalable web trading application, custom CMS and custom landing pages builder.",
            "technologies":"PHP, CakePHP, API libraries, jQuery, ES6, React, Web Sockets (SignalR), Bootstrap CSS, SCSS, Webpack, Redis, PHP Unit Tests, Grunt, GIT, Redmine."
        },
        {
            "url":"https://www.mindgeek.com/",
            "title":"Senior PHP Developer - MindGeek, Nicosia, Cyprus",
            "period":"2017 - 2018",
            "description":"Responsible for the design and implementation of high available and scalable web application with hundreds of thousands of visitors every day. Coordinate and mentor a sub team of 3 developers. Analysis and documentation of the application to be compatible with new GDPR EU regulation.",
            "technologies":"PHP, Zend MVC, Laravel 5, Bootstrap CSS, jQuery, SCSS, Webpack, Redis, Elastic Search, Sphinx, PHP Unit Tests, Grunt, GIT, Jira."
        },
        {
            "url":"https://www.tototheo.com/",
            "title":"Head of Development - Tototheo Group, Limassol, Cyprus",
            "period":"2016 - 2017",
            "description":"Responsible for coordinating a team of 5 developers by gathering requirements, create specifications, assign responsibilities and support on development of the company’s products (3 web applications, 1 embedded system and 2 internal tools)",
            "technologies":"PHP, CakePHP, Bootstrap CSS, jQuery, Java, Python, Perl, VC#, GIT, SVN, Jira"
        },
        {
            "url":"https://www.tototheo.com/",
            "title":"Software Developer - Tototheo Group, Limassol, Cyprus",
            "period":"2012 - 2016",
            "description":"Responsible for the development and design of vessel tracking system based on satellite communications. Helping on the development on other various internal or external applications.",
            "technologies":"PHP, CakePHP, Bootstrap CSS, jQuery, Google Maps API, RabbitMQ, Binary Parsers (Java, Python, Perl), GIS Algorithms, GIT, SVN, Jira"
        },
        {
            "url":"https://www.philips.com/healthcare",
            "title":"Junior Software Developer - Philips Medical Systems, Stuttgart, Germany",
            "period":"2009 - 2010",
            "description":"Implementation of the admin area of the new internal defect & tracking system. Development and integration of new “search & reporting” tool on the new defect & tracking system.",
            "technologies":"VC#, ASP.Net, MS SQL, Crystal Reports, Windows Forms, Windows Services, TCP PDF, XML, XSLT, XPath, NHibernate, Unit Tests, TFS, Document according CMMI standards."
        }
    ];

    const jobsHtml = jobs.map((job, idx) => (
        <Fragment key={idx}>
            <ResumePageItem job={job} />
        </Fragment>
    ));

    return (
        <PageSection id="resume" className="bg-darker-grey">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="text-center">
                            <h2><span className="font-weight-bold">Resume</span></h2>
                        </div>
                    </div>
                </div>
                {jobsHtml}
                <div className="mt-3 text-center w-100">
                    <WhiteLink href={cvPdf} text="Download CV" />
                </div>
            </div>
        </PageSection>
    );
};

export default ResumePage;
