import React, {useEffect} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";

import './MoveToTop.css';

const MoveToTop: React.FC = () => {
    useEffect(() => {
        let timer: any;
        const listener = () => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                let body = document.querySelector('body');
                if (!body){
                    return;
                }
                if (window.scrollY === 0){
                    body.classList.add('dark');
                    // document.querySelector('.back-top').classList.add('d-none');
                }
                else {
                    body.classList.remove('dark');
                    // document.querySelector('.back-top').classList.remove('d-none');
                }
            }, 100);
        };
        window.addEventListener('scroll', listener);
    });
    return (
       <a href="#home" className="move-top">
           <FontAwesomeIcon icon={faChevronUp} />
       </a>
    );
};

export default MoveToTop;
