import React, {useCallback} from 'react';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

interface RecaptchaButtonProps {
    className: string,
    label: string,
    onRecaptcha: (token:string) => void
}

const RecaptchaButton: React.FC<RecaptchaButtonProps> = ({ className= '', label = '', onRecaptcha }) => {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha();
        onRecaptcha(token);
    }, [executeRecaptcha, onRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    // useEffect(() => {
    //     handleReCaptchaVerify();
    // }, [handleReCaptchaVerify]);
    return <button className={className} onClick={handleReCaptchaVerify}>{label}</button>;
};

export default RecaptchaButton;