import React from 'react';
import PageSection from "../PageSection/PageSection";
import SocialLink from "../../components/UI/Links/SocialLink/SocialLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faLinkedin, faFacebookF, faGithub} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

import './ContactPage.css';
import RecaptchaButton from "../../components/UI/Buttons/RecaptchaButton/RecaptchaButton";
import axios from "axios";

const ContactPage: React.FC = () => {
    const formRef = React.createRef<HTMLFormElement>();
    const successRef = React.createRef<HTMLDivElement>();
    const errorRef = React.createRef<HTMLDivElement>();

    const onContactSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
    };
    const onRecaptcha = (token: string) => {
        const frm = formRef.current;
        if (frm){
            if (frm.checkValidity()){
                frm.classList.remove('was-validated');
                let data = new FormData(frm);
                axios({
                    method:'post',
                    url:'/contact.php',
                    data:data
                }).then((response) => {
                    if (response.data.result === 'ok'){
                        successRef.current?.classList.remove('d-none');
                        errorRef.current?.classList.add('d-none');
                    }
                    else {
                        successRef.current?.classList.add('d-none');
                        errorRef.current?.classList.remove('d-none');
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
            else {
                frm.classList.add('was-validated');
            }
        }
    };
    return (
        <PageSection id="contact">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <h2><span className="font-weight-bold">Contact</span> Me</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <SocialLink url="https://www.linkedin.com/in/ditoskas" icon={faLinkedin} />
                                </li>
                                <li className="list-inline-item">
                                    <SocialLink url="https://www.facebook.com/ditoskas" icon={faFacebookF} />
                                </li>
                                <li className="list-inline-item">
                                    <SocialLink url="https://github.com/ditoskas" icon={faGithub} />
                                </li>
                                <li className="list-inline-item">
                                    <a href="mailto:dimitris@toskas.gr" className="social-icon">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <div className="form-contact">
                            <form noValidate={true} ref={formRef} id="frmContact" onSubmit={onContactSubmit} className="needs-validation"
                                  method="post">
                                <div className="d-none success-message" ref={successRef}>
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <strong>Thank you!</strong> Your message has been sent.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                                    </div>
                                </div>
                                <div className="d-none error-message" ref={errorRef}>
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        An error has occured, please try again or send me an <a
                                        href="mailto:dimitris@toskas.gr"> e-mail</a>.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <input name="name" id="name" type="text" className="form-control"
                                                   placeholder="Your Name*" required={true} />
                                                <div className="invalid-feedback">Name can not be empty.</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <input name="email" id="email" type="email" className="form-control"
                                                   placeholder="Your Email*" required={true} />
                                                <div className="invalid-feedback">Invalid E-mail address.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group mt-2">
                                    <textarea name="message" id="message" rows={4} className="form-control"
                                              placeholder="Your message..." required={true} />
                                            <div className="invalid-feedback">Message can not be empty.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-lg-12 text-right">
                                        <RecaptchaButton className="btn btn-blue" label="Send Message" onRecaptcha={onRecaptcha} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

export default ContactPage;
