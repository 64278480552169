import React from 'react';

interface ResumeItem {
    url: string,
    title: string,
    period: string,
    description: string,
    technologies: string
}

interface ResumePageItemProps {
    job: ResumeItem
}

const ResumePageItem: React.FC<ResumePageItemProps> = ({ job }) => {

    return (
        <div className="row mt-5">
            <div className="col-12">
                <div className="card bg-dark-grey w-100">
                    <div className="card-body">
                        <a href={job.url} rel="noreferrer" target="_blank" className="card-title h5 text-light-blue">
                            {job.title}
                        </a>
                        <h6 className="card-subtitle my-2 text-muted">{job.period}</h6>
                        <p className="card-text">
                            {job.description}
                        </p>
                    </div>
                    <div className="card-footer">
                        <span className="text-dark-blue">Technologies:</span> {job.technologies}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumePageItem;
