import React from 'react';
import ButtonLink from "./ButtonLink";

import './WhiteLink.css';

interface WhiteLinkProps {
    href: string,
    text:string
}


const WhiteLink: React.FC<WhiteLinkProps> = ({ href, text }) => {
    return (
       <ButtonLink href={href} target="_blank" className="btn-outline-white btn-round">{text}</ButtonLink>
    );
};

export default WhiteLink;
