import React, {useEffect} from 'react';

import MainMenu from "./components/UI/MainMenu/MainMenu";
import HomePage from "./Pages/HomePage/HomePage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import ResumePage from "./Pages/ResumePage/ResumePage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import MoveToTop from "./components/UI/Links/MoveToTop/MoveToTop";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import './App.css';

const App: React.FC = () => {
    useEffect(() => {
        let timer: any;
        const listener = () => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                let body = document.querySelector('body');
                if (!body){
                    return;
                }
                if (window.scrollY === 0){
                    body.classList.add('dark');
                    // document.querySelector('.back-top').classList.add('d-none');
                }
                else {
                    body.classList.remove('dark');
                    // document.querySelector('.back-top').classList.remove('d-none');
                }
            }, 100);
        };
        window.addEventListener('scroll', listener);
    },[]);

    return (
       <React.Fragment>
         <MainMenu/>
           <HomePage />
           <AboutPage />
           <ResumePage />
           <GoogleReCaptchaProvider
               reCaptchaKey="6Lfbc0waAAAAAOlXq3xeitVmfOdIjaTQke4I_13_"
               scriptProps={{
                   async: true, // optional, default to false,
                   defer: true, // optional, default to false
                   appendTo: 'head', // optional, default to "head", can be "head" or "body",
               }}
           >
               <ContactPage />
           </GoogleReCaptchaProvider>
           <MoveToTop />
       </React.Fragment>
    );
};

export default App;
