import React from 'react';
import Typed from "react-typed";

import PageSection from "../PageSection/PageSection";
import WhiteLink from "../../components/UI/Links/ButtonLink/WhiteLink";
import './HomePage.css';

import cvPdf from '../../assets/files/Dimitrios-Toskas-CV.pdf';

const HomePage: React.FC = () => {

    return (
       <PageSection id="home" className="bg-img h-100vh">
           <div className="d-table w-100 h-100">
               <div className="d-table-cell valign-middle">
                   <div className="container">
                       <div className="row">
                           <div className="col-12 text-center">
                               <h1 className="font-nunito text-white">Welcome</h1><br/>
                               <h2 className="type-text">
                                   <Typed strings={["I'm Toskas Dimitrios.","I'm Software Engineer."]} smartBackspace={true} backDelay={3e3} loop={true} typeSpeed={100} />
                               </h2>
                               <WhiteLink href={cvPdf} text="Download CV" />
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </PageSection>
    );
};
export default HomePage;
