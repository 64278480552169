import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './SocialLink.css';
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";

interface SocialLinkProps {
    url: string,
    icon: IconDefinition
}

const SocialLink: React.FC<SocialLinkProps> = ({ url, icon }) => {
    return (
        <a href={url} rel="noreferrer" target="_blank" className="social-icon">
            <FontAwesomeIcon icon={icon} />
        </a>
    );
};

export default SocialLink;
